<template lang="pug">
  div
    base-snackbar(
      :errors.sync="revenueSourcePostErrors"
    )
    v-row
      v-col(cols="12")
        v-text-field(
          label="Code"
          v-model="code"
          :error-messages="revenueSourcePostErrors.code"
        )
      v-col(cols="12")
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="revenueSourcePostErrors.description"
        )
      v-col(:cols="editMode ? 6 : 12")
        v-btn(
          block
          :color="editMode ? 'yellow darken-2' : 'primary'"
          dark
          small
          :loading="revenueSourcePosting"
          @click="sendRequestRevenueSources"
        )
          span save
      v-col(v-if="editMode" cols="6")
        v-btn(
          block
          color="red"
          dark
          small
          @click="cancel"
        )
          span cancel
</template>
<script>
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [revenueSourcePostVars, revenueSourcePostVarNames] = requestVars({ identifier: 'revenue-source', request: 'post', hasData: false })

const revenueSourcePostHandler = new VueRequestHandler(null, revenueSourcePostVarNames)

const inputVars = () => ({
  id: null,
  code: null,
  description: null,
})

export default {
  name: 'CreateRevenueSource',
  props: {
    defaultValue: {
      type: Object,
      default: () => ({ }),
    },
  },
  data () {
    return {
      ...inputVars(),
      ...revenueSourcePostVars,
    }
  },
  computed: {
    editMode () {
      return !this.$objectEmpty(this.defaultValue)
    },
  },
  watch: {
    defaultValue (item) {
      this.populateInputs(item)
    },
  },
  methods: {
    populateInputs (item) {
      Object.keys(item)
        .forEach(key => {
          this[key.snakeToCamelCase()] = item[key]
        })
    },
    sendRequestRevenueSources () {
      if (this.editMode) {
        this.updateRevenueSources()
        return
      }
      this.storeRevenueSources()
    },
    updateRevenueSources () {
      const handler = revenueSourcePostHandler
      const repository = revenueSourceRepository.update
      const { id, data } = this.getInputFields()
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    storeRevenueSources () {
      const handler = revenueSourcePostHandler
      const repository = revenueSourceRepository.store
      const { data } = this.getInputFields()
      handler.setVue(this)
      handler.execute(repository, [data], this.reset)
    },
    getInputFields () {
      const excluded = ['id']
      const data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((accum, key) => {
          accum[key.camelToSnakeCase()] = this[key]
          return accum
        }, {})
      const { id } = this
      return { id, data }
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    cancel () {
      this.$emit('update:defaultValue', {})
      this.reset()
    },
  },
}
</script>